import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// import { Router } from "@reach/router"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { CardProject } from "../../../components/card-project/card-project"
import { HeadingBasic } from "../../../components/heading/heading"

// import ProjectDetail from "../../../templates/project-detail"
// import BSDTollRoad from "../../../templates/bsd-toll-road"
// import BSDTrainStation from "../../../templates/bsd-train-station"
// import BSDBusStation from "../../../templates/bsd-bus-station"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const TownshipPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bsdCity: file(relativePath: { eq: "imgBsdCity.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kotaWisata: file(relativePath: { eq: "imgKotaWisata.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      nuvasaBay: file(relativePath: { eq: "imgThumnail101.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      deltamas: file(relativePath: { eq: "imgKotaDeltamas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        propertyType(id: "township", idType: SLUG) {
          name
          description
          acfPropertyType {
            image {
              sourceUrl
            }
            excerpt
            banner {
              sourceUrl
            }
          }
          translation(language: ID) {
            name
            description
            acfPropertyType {
              image {
                sourceUrl
              }
              excerpt
              banner {
                sourceUrl
              }
            }
          }
          projects {
            nodes {
              id
              title
              slug
              excerpt
              townshipInformation {
                hectares
              }
              locations {
                nodes {
                  name
                }
              }
              featuredImage {
                sourceUrl
                caption(format: RAW)
                mediaDetails {
                  sizes {
                    name
                    width
                    height
                    file
                    sourceUrl
                  }
                }
              }
              translation(language: ID) {
                title
                slug
                excerpt
                townshipInformation {
                  hectares
                }
                locations {
                  nodes {
                    name
                  }
                }
                featuredImage {
                  sourceUrl
                  caption(format: RAW)
                  mediaDetails {
                    sizes {
                      name
                      width
                      height
                      file
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.propertyType?.translation || data?.wordPress?.propertyType
  const projects = data.wordPress.propertyType.projects.nodes

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={wordPress && wordPress.name}
        fbTitle={wordPress && wordPress.name}
        twitterTitle={wordPress && wordPress.name}
        twitterDescription={wordPress && wordPress.description}
        fbDescription={wordPress && wordPress.description}
        description={wordPress && wordPress.description}
        url={location?.href}
        img={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
        twitterImg={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
        img={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={wordPress && wordPress.name}
        img={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
        alt={wordPress && wordPress.name}
        className="cover-md cover-detail"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      <section className="py-main">
        <div className="container container-md">
          <HeadingBasic
            text={[wordPress && wordPress.description]}
            className="mb-0"
          />
        </div>
      </section>
      <section>
        <div className="row row-0">
          {projects?.map(item => {
            return (
              <CardProject
                key={item.title}
                title={item.translation?.title || item.title}
                description={
                  item.excerpt ||
                  "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                }
                img={
                  item?.translation?.featuredImage?.sourceUrl ||
                  item?.featuredImage?.sourceUrl
                }
                // area="6000 hectares"
                location={
                  item.locations &&
                  item.locations.nodes.length > 0 &&
                  item.locations.nodes[0].name
                }
                area={item.townshipInformation.hectares}
                link={`/development/township/${item.slug}`}
                className="ratio-2-1 col-md-6 px-0"
              />
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

// const Township = ({ uri }) => (
//   <Router basepath="/development/township">
//     <TownshipPage path="/" location={uri} />
//     <ProjectDetail path="/:slug" />
//     <BSDTollRoad path="/bsd-city/toll-road" />
//     <BSDTrainStation path="/bsd-city/train-station" />
//     <BSDBusStation path="/bsd-city/bus-station" />
//   </Router>
// )

export default TownshipPage
